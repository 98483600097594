<template>
  <div class="spinner text-center mt-10">
    <span class="head text-6xl">
      ( ͡° ͜ʖ ͡°)
    </span>
    <div class="mt-10 text-xl">
      Getting data from blockchain...
    </div>
  </div>
</template>

<style lang="scss">
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-0.35em);
  }
}

.spinner .head {
  display: inline-block;

  animation-name: bounce;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

  transform: translateY(10px);
}
</style>