function notifySuccess(text, timeout = 5000) {
  new window.Noty({
    text,
    type: 'success',
    timeout,
    theme: 'metroui',
    layout: 'bottomRight'
  }).show();
}


function notifyError(text, timeout = 5000) {
  new window.Noty({
    text,
    type: 'error',
    timeout,
    theme: 'metroui',
    layout: 'bottomRight'
  }).show();
}


function notifyTransaction(tx, message = 'Transaction sent.') {
  let n = new window.Noty({
    text: message,
    type: 'success',
    timeout: 0,
    theme: 'metroui',
    layout: 'bottomRight',
    buttons: [
      Noty.button('View on Etherscan', 'btn', function() {
        window.open('https://etherscan.io/tx/' + tx.hash);
      }),
      Noty.button('Close', 'btn', function() {
        n.close();
      }),
    ]
  });

  n.show();
}


export {
  notifyError,
  notifySuccess,
  notifyTransaction
};
